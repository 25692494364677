.d-block{
    display:block;
}
.computer-vision{
    max-width:416px;
}
.computer-vision p{
    max-width:489px;
}
.bg-green{
    background:#45BEA0;
}
.dori-plateform-bg{
    position:relative;
}
.dori-plateform-bg{
    max-width: 540px;
    height: 300px;
    float: right;
}

.vertical-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 8px 8px 8px 8px;
}
.dori-plateform-box{
    display: flex;
    background: #45BEA0;
    padding: 30px 30px 30px 50px;
    color: #ffffff;
    max-width: 497px;
    position: relative;
    left: -50px;
    min-height: 204px;
    align-items: center;
    border-radius: 8px;
}
.dori-plateform-box .subtitle{
    padding-left:30px;
}
.dori-plateform-box img{
    width:53px;
    height:52px;
}

.svg-icon-color-blue{
    fill: #45BEA0;
    font-size: 35px ;
}
.our-company-value svg{
    width:48px;
    height:36px;
    fill:#45BEA0;
}
.our-company-value svg path{
    fill:#45BEA0;
}
.grid-outer{
    justify-content:space-between;
}
.grid-outer .MuiGrid-root{
    max-width:358px;
    box-shadow: 0px 8px 16px #00000014;
    border: 1px solid #C0CCD2;
    border-radius: 4px;
    padding: 48px 30px;
}

/*---------------Our Mission Vision Start-------------*/
.our-mision-subheading{
    max-width:410px;
}
.over-mission-subheading.center-subheading{
  max-width:787px;
}
.short-divider {
    max-width: 285px;
    border-top: 0.1px solid #C0CCD2;
    margin: 0 auto;
}
.our-mission-vision{
    background-position:bottom;
    background-repeat: no-repeat;
    background-size: cover;
}
/*---------------Our Mission Vision End-------------*/
/*-------------- How we are Section Start --------------*/
.how-we-are .grid-outer .MuiGrid-root{
    box-shadow:none;
    border:none;
}
.number-box {
    align-items: center;
    background: #ECF9F6;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;
}
.how-we-are .MuiGrid-root:nth-child(1), .how-we-are .MuiGrid-root:nth-child(2), .how-we-are .MuiGrid-root:nth-child(3){
    padding-top:0px;
    
}
.how-we-are .MuiGrid-root:nth-child(4), .how-we-are .MuiGrid-root:nth-child(5), .how-we-are .MuiGrid-root:nth-child(6){
    padding-bottom:0px;
    padding-left:0px;
}
.how-we-are .MuiGrid-root{
    padding-left:0px;
    padding-right:0px;
}
.how-we-are .grid-outer .MuiGrid-root{
    margin: 0px 0px !important;
}
.short-divider.short-divider-80{
    max-width:1017px;
}
/*-------------- How we are Section End --------------*/
 /*-------------- Our Team Section Start --------------*/
 .our-team .grid-outer .MuiGrid-root {
    box-shadow: none;
    border: 0;
    border-radius: 4px;
    padding: 0px 0px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.profilePic{
    width:178px;
    height:178px;
    border-radius:50%;
    overflow:hidden;
}
.bg-pattern{
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius:0px;
}
.linkcolor{
    color:#45BEA0;
}
.linkcolor:hover{
    color:#45BEA0;
}
 /* -------------- Our Team Section End -------------- */

 /* -------------- Our Trusted & Technology Partnerships Section Start -------------- */
 .grid-outer .MuiGrid-root.client-logo {
    width:154.75px;
    height:81.85px;
    max-width: 155px;
    box-shadow: none;
    border: 1px solid #D6DEE2;
    border-radius: 4px;
    padding: 25px 50px;
    max-height: 82px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 36px;
}
.grid-outer .MuiGrid-root.client-logo:nth-child(4), .grid-outer .MuiGrid-root.client-logo:nth-child(5), .grid-outer .MuiGrid-root.client-logo:nth-child(6){
   margin-bottom:0px;
}
.technology-partnerships .grid-outer{
  max-width:532px;
  margin: 0 auto;
}
 /* -------------- Our Trusted & Technology Partnerships Section End -------------- */

/* -------------- Mobile View Start -------------- */

@media (max-width:767px) {
    .short-divider.short-divider-80 {
        max-width: 80%;
    }
    .technology-partnerships .grid-outer {
        max-width: 342px;
    }
    /* computer vision section start */
    .computer-vision-right{
        justify-content: flex-end;
        padding-top:30px;
        display: flex;
    }
    .dori-plateform-box {
        padding: 15px 15px 15px 15px;
        left: -30px;
    }
    .dori-plateform-box .subtitle {
        padding-left: 15px;
    }
    .dori-plateform-box img {
        width: 46px;
        height: 42px;
    }
    .dori-plateform-bg {
        height: 263px;
        width: calc(100% - 30px);
    }
    
    /* computer vision section end */
    .grid-outer .MuiGrid-root:nth-child(2){
        margin:20px 0px;
    }
    .grid-outer .MuiGrid-root {
        max-width:100%;
    }
    /* our team section start */
    .our-team .grid-outer .MuiGrid-root:nth-child(2) {
        margin: 0px 0px 30px 0px;
    }
    .our-team .grid-outer .MuiGrid-root {
        margin: 0px 0px 30px 0px;
    }
    .our-team .grid-outer .MuiGrid-root:last-child {
        margin: 0px 0px 0px 0px;
    }
    /* How we are section Start */
    .how-we-are .grid-outer .MuiGrid-root:nth-child(3) {
       padding-bottom:0px;
    }
    /* -------------- How we are Section End -------------- */

    /* -------------- Our Trusted & Technology Partnerships Section Start -------------- */
     .technology-partnerships .grid-outer .MuiGrid-root:nth-child(2) {
        margin: 0px 0px;
    }
    /* -------------- Our Trusted & Technology Partnerships Section End -------------- */

}

/*---------------------------------Mobile View End-----------------------------------------------*/

/*-------------------------Ipad View Start-------------------------------------------------------*/

@media (min-width:768px) and (max-width:1023px) {
    .short-divider.short-divider-80 {
        max-width: 80%;
    }
    .computer-vision p {
        max-width: 80%;
    }
    .computer-vision-right{
        justify-content: center;
        padding-top: 60px;
        display: flex;
    }
    /* our company valuesection Start */
    .our-company-value .grid-outer .MuiGrid-root {
       margin-bottom:20px;
    }

    .our-company-value .grid-outer .MuiGrid-root:nth-child(3) {
        margin-bottom:0px;
     }
     .our-company-value .grid-outer {
        max-width: 765px;
        justify-content: space-around;
        margin: 0 auto;

    }
   /* our company valuesection End */

    /* our team section Start */
    .our-team .grid-outer .MuiGrid-root:nth-child(3), .our-team .grid-outer .MuiGrid-root:nth-child(4) {
        margin-bottom: 0px;
        margin-top: 0px;
    }
    .our-team .grid-outer .MuiGrid-root {
        margin-bottom: 30px;
    }
     /* our team section End */

    /* How are we section Start */
    .how-we-are .MuiGrid-root:nth-child(4), .how-we-are .MuiGrid-root:nth-child(5), .how-we-are .MuiGrid-root:nth-child(6) {
        padding-top: 0px;
    }
    /* How are we section End */
    
    /* Our Partners Section Start */
    .our-team .grid-outer .MuiGrid-root:nth-child(2) {
        margin-bottom:30px;
    }
   
    /* Our Partners Section End */
   /* -------------- Our Trusted & Technology Partnerships Section Start -------------- */
     .technology-partnerships .grid-outer .MuiGrid-root:nth-child(3) {
        margin: 0px 0px;
    }
    /* -------------- Our Trusted & Technology Partnerships Section End -------------- */
}
/*-------------------------Ipad View End---------------------------------------------------------*/




/*-------------------------Ipad Pro View Start-----------------------------------*/

@media (min-width: 1024px) and (max-width: 1179px) {
    .short-divider.short-divider-80 {
        max-width: 80%;
    } 
    .computer-vision p {
        max-width: 80%;
    }
    .computer-vision-right{
        justify-content: center;
        padding-top:60px;
        display: flex;
    }

    .grid-outer .MuiGrid-root {
        max-width: 320px;
    }
    /* -------------- Our Trusted & Technology Partnerships Section Start -------------- */
    .technology-partnerships .grid-outer .MuiGrid-root:nth-child(3) {
        margin: 0px 0px;
    }
    /* -------------- Our Trusted & Technology Partnerships Section End -------------- */
    
}

/*-------------------------Ipad Pro View End-----------------------------------*/