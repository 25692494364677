.root {
  
    background-color: white;
        display:flex;
        height: auto;
       
    
  }
  .image-box{display: flex;
    align-items: center;}
  .tabs {border-right:1px solid ;}
  .root .MuiTabs-root{width:50%;}
  .tab-control {
    padding: 0px 0px 20px 0px !important;
    font-size: 18px !important;
    max-width: 450px !important;
    opacity: 1 !important;
    color:#091116;
    min-width: 72px;
    min-height: 48px;
    text-align: center;
    flex-shrink: 0;
    font-family: 'Lato', sans-serif !important;
    font-weight: 400 !important;
    line-height: 28px;
    white-space: normal;
    letter-spacing: 0px !important;
    text-transform:unset !important;
    
   
}
.tab-control .MuiTab-wrapper{ align-items:start !important; background-color: #EAEEF0; border-radius: 6px !important;   padding: 15px 30px;}
.MuiTab-textColorInherit.Mui-selected .MuiTab-wrapper{
    background-color: #2E5668;
    color:#fff;
}
.tab-control .MuiTouchRipple-root{display: none !important;}
.root.PrivateTabIndicator-vertical-4{display: none !important;}
.root .jss11 {display: none !important;}
.root .MuiTabs-indicator  {display: none !important;}
@media (max-width:767px) {
.root {display: block; height: 300px;}
.root .MuiTabs-root {width: 100%;}
.root .MuiTabs-flexContainerVertical {
    flex-direction: row;
    overflow-x: scroll;
    justify-content: space-between;
}
}