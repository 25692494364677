.overview .inner-div{max-width:475px ;}
.How-we-are-Different .grid-outer .grid-inner{box-shadow: 0px 8px 16px #00000014;
    border: 1px solid #587886;
    border-radius: 4px; padding: 50px 30px 50px 30px; background-color: #2A4E5E; margin-top: 40px;}
    .How-we-are-Different .number-box{background-color: #2E5668;}
    .How-we-are-Different  .inner{max-width: 550px;}
    .testomonials .test-inner{ box-shadow: none; max-width: 48%; background: #EBEFF0; padding: 48px 45px; border: 1px solid #C0CCD2;  border-radius: 4px;}
    .testomonials .test-inner svg {margin-bottom: 30px; width: 39px; background-color: #2E5668; padding: 6px;  height: 39px; border-radius: 4px;}
    .testomonials .test-inner svg path{color: #EBEFF0;}
    @media (max-width:767px) {
        .How-we-are-Different .grid-outer .grid-inner{margin-top: 30px;}
        .testomonials .test-inner {
            max-width: 100%; padding: 30px;}
}


    @media (min-width:768px) and (max-width:1023px) {
        .How-we-are-Different .grid-outer .grid-inner{margin-top: 15px;}
        .How-we-are-Different  .inner{margin-bottom: 21px;}
        .overview .inner-div { max-width: 100%;}
        .testomonials .test-inner {max-width: 100%; margin-bottom: 30px;}
    }