.What-Happens-Next .grid-outer .grid-inner{box-shadow: 0px 8px 16px #00000014;
    border: 1px solid #C0CCD2;
    border-radius: 4px; background-color: #ffffff; padding: 30px;}
    .Contact-us-section .contact-from {background-color: #fff; display: flex; justify-content: flex-end;}
    .Contact-us-section .contact-from .form{max-width: 695px;
    width: 100%;
    float: right;}
    .Contact-us-section .about-dori {display: flex; justify-content: flex-start;}
    .Contact-us-section .about-dori .form{max-width: 500px;
        width: 100%;
        float: right; padding-left: 60px;}
        .Contact-us-section .about-dori .form svg{width: 18px; height: 24px;}
        .Contact-us-section .about-dori .form svg path{fill:#fff;}
        .Contact-us-section .about-dori .form .border{border-right: 1px solid #C0CCD2; margin: 0px 35px; 
           height:  83px;}
        .Contact-us-section .about-dori .form ul{display: flex; list-style: none;}
        .Contact-us-section .about-dori .form .para{max-width: 340px;}
        .map{display: flex; align-items: center; }
        .map img{margin-right: 20px;}
        @media (min-width: 768px) and (max-width: 1023px){
            .Contact-us-section .contact-from .form {margin-left: 20px; max-width: 100%; margin-bottom: 40px; float: unset;}
        .Contact-us-section .about-dori .form {padding-left: 20px; padding-right: 20px;  max-width: 100%; float: unset;}
            .Contact-us-section .contact-from {
                background-color: #fff;
                display: block;
                justify-content: unset;
            }
            .Contact-us-section .about-dori {
                display: block;
                justify-content: unset;
            }
            .Contact-us-section .about-dori br{display: none;}
            .What-Happens-Next .grid-outer {
                justify-content: space-around;
                margin: 0 auto;
                max-width: 765px;
            }
            .What-Happens-Next .grid-outer .MuiGrid-root {margin-bottom: 20px;}
            .how-we-are .grid-outer .MuiGrid-root {margin: 10px 0px !important;}
}
@media (min-width: 320px) and (max-width: 767px){
  .form .hbspt-form  form {
    width: 100% !important;
}
    .Contact-us-section .contact-from .form {  max-width: 100%; margin-bottom: 40px; float: unset;}
    .Contact-us-section .about-dori .form {padding-left: 20px; padding-right: 20px;  max-width: 100%; float: unset;}
    .Contact-us-section .contact-from {
        background-color: #fff;
        display: unset;
        justify-content: unset;
        margin-left: 20px; padding-right: 20px;
    }
    .Contact-us-section .padding-top-bottom-60 {
        padding-top: 35px;
        padding-bottom: 35px;
    }
    .Contact-us-section .about-dori .form .border {
        margin: 0px 27px;
        
    }

    .Contact-us-section .about-dori {
        display: unset;
        justify-content: unset;
    }
    .Contact-us-section .about-dori br{display: none;}
    .find-us  .h4-heading {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0px;
    }
    .What-Happens-Next .grid-outer {
        justify-content: space-around;
        margin: 0 auto;
        max-width: 765px;
    }
    .What-Happens-Next .grid-outer .MuiGrid-root {
         margin: 20px 0px 0px 0px !important; 
    }
    .What-Happens-Next .grid-outer .MuiGrid-root:nth-child(3) {
        padding-bottom: 30px;
    }
}