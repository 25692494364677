/*banner*/
.CommonBanner  h1{max-width: 700px;}
/*Visual-Intelligence*/
.Intelligence-Platform h4{min-height: auto;}
.platform-max-width {max-width: 600px; margin: auto;}
/**trusted by section*/
.Key-Use-Cases .box-inner .box{box-shadow: 0px 8px 16px #00000014; border-radius:8px; background-color: white;}
.Key-Use-Cases  .box-inner .box{ border-radius:8px;}
.Key-Use-Cases  .box-inner .inner-text{padding:30px 20px;}
.Key-Use-Cases  .box-inner img{border-radius: 8px 0px 0px 8px; width:198px; }
.Key-Use-Cases  .case-study-anchor{color:#1D343E;}
.Key-Use-Cases  .case-study-anchor:hover{color:#45BEA0;}
.Key-Use-Cases  .box-inner .box{  display:flex; }
.Key-Use-Cases  .box-inner .trusted-box1{width: 35%;}
.Key-Use-Cases  .box-inner .trusted-box2{width: 75%;}
.border-inner {
    border-bottom: 1px solid #C0CCD2;
    margin-bottom: 20px;
    margin-top: 30px;
}
/*Key_Results*/
.Key_Results .company-max-width{max-width: 700px; margin:auto;}
.Key_Results .box-inner .box{      padding: 30px 30px 30px 30px; display:flex; background-color: #fff; box-shadow: 0px 8px 16px #00000014; border: 1px solid #C0CCD2;
    border-radius: 4px;
    opacity: 1; min-height:13vh;}
.Key_Results  .box-inner .trusted-box1{width: 35%;}
.Key_Results  .box-inner .trusted-box2{width: 75%; padding-left:10px;}
.Key_Results .svg-icon-color-blue path{
    fill: #45BEA0;
    font-size: 20px;
}
.gear{width: 50px;}
.increase{width: 30px;
    position: absolute;
    margin-left: 30px;
    margin-top: -14px;}
    .box-icon{position: absolute;width: 14px;margin-left: 21px;}
    .factory{position: absolute;width: 14px; margin-left: 18px; margin-top: 7px;}
    .smile{width:40px !important;}
    .factory path{fill: #fff !important;}
    .ten{margin-left: 0px;
        margin-top: 4px;}
    .Simplified .box-inner .box{box-shadow: none !important;}
    .Simplified  .box-inner .inner-text {padding: 24px 0px 0px 0px ;}
    .Simplified .box-inner img{border-radius: 0px 0px 0px 0px !important;}
    
    .case-study-button {margin-top: 48px !important;}

    /***********media queries**************/
@media (max-width:767px) {
    /*second section*/
    .Simplified .box-inner .inner-text h4{min-height: auto;}
    .mobile{display: block;}
    .desktop{display:none;}
    /*second section*/
    /*third section*/
    .Key_Results .box-inner .box{min-height: 13vh;}
    .Key_Results .box-inner .trusted-box2 {
        width: 85%;
        padding-left:0px;
        
    }
    .Key_Results .box-inner .box {
        padding: 20px 20px 20px 20px;}
    .Key-Use-Cases .box-inner .trusted-box1{width: 100%;}
.Key-Use-Cases .box-inner .trusted-box2{width: 100%;}
.Key-Use-Cases .box-inner img {
    border-radius: 8px 8px 0px 0px;
    width: 100%;
    height: auto;
}
.Key-Use-Cases .box-inner .box {
    max-height: 100% !important;
    display: block;
    
}
.Simplified  .box-inner .inner-text {padding: 24px 0px 0px 0px ;}
}


@media (min-width:768px) and (max-width:1023px) {
    .Key_Results .box-inner .box {
        padding: 30px 20px 30px 20px;}
        .Key_Results  .box-inner .trusted-box2{width: 75%; padding-left:0px;}
}

@media (max-width:375px) {.Key_Results .box-inner .box{min-height: 16vh;}}
@media (min-width: 1024px) and (max-width: 1179px) { 
    
}
@media (min-width: 1281px) and (max-width: 1440px){
.Key_Results .box-inner .box{min-height: 20vh;}

}
@media (min-width: 1100px) and (max-width: 1280){
    .Key_Results .box-inner .box{min-height: 11vh;}
    
    }