.blogs .min-read{color:#BFBFBF;}
.custom-container2{max-width: 900px;
    margin-left: auto;
    margin-right: auto;}
    .blogs .readmorelink {
        color: #1D343E;
        width: 16%;
    }
    .border-blog {
        border-bottom: 1px solid #C0CCD2;
        margin-bottom: 60px;
        margin-top: 60px;
    }
    .dori-blog-bg {
       
        float: right;
    }

    .paginationBttns {
        width: 100%;
        height: 40px;
        list-style: none;
        display: flex;
        justify-content: center;
      }
      
      .paginationBttns a {
        padding: 4px 12px;
        margin: 16px;
        border-radius: 2px;
        font-size: 20px;
        line-height: 30px;
        color: #1D343E;
        cursor: pointer;
      }
      .next a{font-size: 30px; color: #000;}
.previous  a{font-size: 30px; color: #000;}
      .paginationBttns a:hover {
        color: white;
        background-color: #45BEA0;
      }
      
      .paginationActive a {
        color: white;
        background-color: #45BEA0;
      }
      .catagoriy {text-align: center;
        padding-bottom: 108px;
    }
    .catagoriy a{border: 1px solid #1D343E;
        border-radius: 21px; padding: 12px 32px; color: #1D343E !important; margin: 0 20px; cursor: pointer; display: inline-block;} 
        .catagoriy a:hover{border: 1px solid #45BEA0;
            border-radius: 21px; background-color: #45BEA0; text-decoration: none;} 

    .blog-banner img{height:40vh ;}

    @media (min-width: 768px) and (max-width: 1023px){
        .dori-blog-bg {float: unset;}
        .recentPosts  .computer-vision-right {display: block;}
    }
    @media (min-width: 320px) and (max-width: 767px){

      .recentPosts  .computer-vision-right {
            display: flex;
            padding-top: 30px;
            justify-content: flex-start !important;
        }
    }
       
   