
.accordeon  {   border-top: solid 1px #C0CCD2; max-width: 85%;
  margin: auto;}
.accordeon .box { padding: 40px 0px;   border-bottom: solid 1px #C0CCD2;}
.accordeon .title {  width: 100%; }
.accordeon .box.active .title {  width: 65%; }
.accordeon .box .body { display: none; }
.accordeon .box.active .body { display: block; padding: 0px 30px; }
.inner-accordian-div{display: flex; position: relative;} 
.inner-accordian-div::after{content: "+";   font-size: 30px;
  color: #1D343E;
  display: flex;
  align-items: center; }
  .accordeon .box.active .inner-accordian-div::after{content: "-";   font-size: 40px;
    color: #45BEA0;
    display: flex;
    align-items: center;}
    @media (max-width:767px) {
     .inner-accordian-div { display: block; }
     .accordeon { max-width: 100%;}
     .accordeon .box.active .title {width: 100%; margin-bottom: 30px; }
     .inner-accordian-div::after{ display: block; float: right; margin-top: -30px;}
     .accordeon .box.active .body {padding: 0px 20px 0px 0px;  }
     .accordeon .box { padding: 30px 0px;}
     .accordeon .box  .h3-heading {
      font-size: 21px;
      line-height: 27px;
      letter-spacing: 0px;
  }
      .accordeon .box.active .inner-accordian-div::after{
        display: block; float: right; margin-top: -150px;}
      }
      @media (min-width:768px) and (max-width:1023px) {
        .inner-accordian-div { display: block; }
        .accordeon { max-width: 90%;}
        .accordeon .box.active .body {
          display: block;
          width: 80%;
          padding: 0px 0px;
      }
        .accordeon .box.active .title {width: 100%; margin-bottom: 30px; }
        .inner-accordian-div::after{ display: block; float: right; margin-top: -30px;}
         .accordeon .box.active .inner-accordian-div::after{
           display: block; float: right; margin-top: -125px;}
         }