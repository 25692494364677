
.Data-Driven-section .number-box{display: block;}
.Data-Driven-section .svg-icon-color-blue{fill: #45bea0; width: 40px; height: 20px;}
.Data-Driven-section .number-box span{position: relative;}
.Data-Driven-section  .code-icon{width: 10px;position: absolute; left: 15px;top: 10px;}
.reach-out-secgtion .center-subheading {max-width: 682px;}
#plateformBox ul{display: flex; justify-content: space-between;}
.plateformBoxFirst .center-subheading{margin: auto;}
.plateformBoxFirst .Data-Driven-section  .MuiGrid-root {
  padding-left: 30px;
  padding-right: 30px;
}
.plateformBoxFirst .Data-Driven-section .inner{background:#fff;
  box-shadow: 0px 8px 16px #00000014 !important;
  border: 1px solid #C0CCD2  !important;
  border-radius: 4px; padding: 30px 30px 30px 30px !important; margin-top:20px !important; }
#plateformBox ul li{display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  flex-direction: column;
  text-align: center;
  width: 22%;}
/*#plateformBox{
  display:flex;
  width: 100%;
  max-width:1010px;
  justify-content:flex-start;
  background:transparent;
  margin:auto;
 
}


.plateformBoxFirst {
  min-width: 156px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color:#436777;
  position: relative;
  padding-bottom: 0px;
}
.plateformBoxFirst ul {
  margin: 21px 0px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}
.plateformBoxFirst ul li{
  display: flex;
  align-items: center;
  justify-content: center;
}
.item1{background-color: #45bea0;}
.d-none{
  display:none;
  
  max-height: 0;            
  overflow-y: hidden;
  -webkit-transition: max-height 0.6s ease-in-out;
  -moz-transition: max-height 0.6s ease-in-out;
  -o-transition: max-height 0.6s ease-in-out;
  transition: max-height 0.6s ease-in-out;
}
.padding-top-16{
  padding-top:16px;
}
.plateformBoxFirst:before {
  position: absolute;
  width: 100%;
  height: 40px;
  border-radius: 50%;
  background: #567785;
  content: '';
  top: -21px;
}
.plateformBoxFirst:after {
  position: absolute;
  width: 100%;
  height: 40px;
  border-radius: 50%;
  background: #567785;
  content: '';
  bottom: -19px;
  z-index: 9999;
}
.plateformBoxSecond h3{
  /* background-image: url(../Images/mark-icon.svg); */
   /*    background-repeat: no-repeat;
    background-position: 0px 6px;
    overflow: visible;
    position: relative;
    left: -44px;
    padding-left: 55px;
    cursor: pointer;
    color: #fff;
    margin-bottom: 54px;
    font-size: 24px;
    line-height: 30px;
}
.plateformBoxSecond h3:hover{color: #45bea0;}
.bodyRemoveScroll .dd .plateformBoxSecond h3{color: #45bea0;}
#plateformBox.active .plateformBoxSecond h3{color: #45bea0;}

#plateformBox.active .d-none{display:block; max-height: 600px ;    }
#plateformBox.active .plateformBoxSecond h3{cursor: auto;}
.bodyRemoveScroll .dd{background-color: #1D343E !important;}
.bodyRemoveScroll .dd .d-none{display: block; max-height: unset;}
.bodyRemoveScroll .dd .plateformBoxFirst{background-color: #1D343E;}
.bodyRemoveScroll .dd  .plateformBoxFirst:before{background: #344952; }
.bodyRemoveScroll .dd  .plateformBoxFirst:after{background: #344952;}
#plateformBox.active {background-color: #1D343E; opacity: 1;}
#plateformBox.active .plateformBoxFirst{background-color: #1D343E;}
#plateformBox.active .plateformBoxFirst:before{background: #344952; z-index:9999;}
#plateformBox.active .plateformBoxFirst:after{background: #344952;}
h3.text-green.margin-bottom-54.h3-heading:hover {
  color: #fff;
}

.plateformBoxSecond h3:before {
  position: absolute;
  width: 100%;
  height: 40px;
  border-radius: 50%;
 
  content: '';
  bottom: initial;
  z-index: 9999;
  width: 40px;
  z-index: 999999999999;
  left: -50px;
  top: -6px;
 
}
.plateformBoxFirst ul li{
  margin-bottom:36px;
}
.plateformBoxFirst  span li {
  padding-bottom: 40px;
}
#plateformBox:first-child .plateformBoxFirst ul{margin-top: 40px;}
#plateformBox:nth-child(2) .plateformBoxSecond{padding-top: 55px;}
#plateformBox:nth-child(2) .plateformBoxFirst span li {
  margin-bottom: 23px !important;
}
#plateformBox:nth-child(3) .plateformBoxSecond{padding-top: 55px;}
#plateformBox:nth-child(3) .plateformBoxFirst span li {
  margin-bottom: 23px !important;
}
#plateformBox:nth-child(4) .plateformBoxSecond{padding-top: 55px;}
#plateformBox:nth-child(4) .plateformBoxFirst span li {
  margin-bottom: 23px !important;
}
.plateformBoxFirst ul li:last-child{
  margin-bottom:0px;
}
.plateformBoxFirst  span li:last-child {
  padding-bottom: 30px;
}
.plateformBoxSecond {
  padding-left: 30px;
  border-left: 1px solid #5a6a71;
  padding-top: 25px;
  min-width: 340px;
  max-width: 340px;
  margin-left: 70px;

  
  
}
#plateformBox:first-child  .plateformBoxSecond {
  
  margin-top: 40px;
  
}
.plateformBoxSecond ul li{list-style: none;}
.plateformBoxSecond ul li:before{content: ". ";
    font-size: 40px;
    color: #45bea0;;
    position: relative;
    top: -2px;}
.plateformBoxThird {
  margin-right: 32px; display: flex; align-items: center;}
.plateformBoxThird p{
 
  background: #344952;
  padding: 48px 30px;
  height: max-content;
  border-radius: 4px;
  
}
.padding-top-36{
  padding-top:36px;
}
.margin-top-36{
  margin-top:36px;
}
.margin-top-76{
  margin-top:160px;
}
.margin-top-102{
  margin-top:102px;
}
.margin-bootom-54{
  margin-bottom:54px;
}
.margin-bottom-58{
  margin-bottom:58px;
}
.margin-bottom-74{
  margin-bottom:74px;
}
.margin-bottom-67{
  margin-bottom:67px;
}
.margin-bottom-72{
  margin-bottom:72px;
}
.margin-bottom-65{
  margin-bottom:65px;
}
.margin-bottom-63{
  margin-bottom:63px;
}
.margin-bottom-56{
  margin-bottom:56px;
}
.margin-bottom-71{
  margin-bottom:71px;
}
.margin-bottom-44{
  margin-bottom:44px;
}
.margin-bottom-54{
  margin-bottom:54px;
}
.margin-bottom-45{
  margin-bottom:45px;
}

.margin-bottom-56{
  margin-bottom:65px;
}
.margin-bottom-58{
  margin-bottom:68px;
}*/
/*.CommonBanner {
  max-height: 383px !important;
}*/
@media (min-width:768px) and (max-width:1023px)  {
  .plateformBoxFirst .Data-Driven-section .MuiGrid-root {
    padding-left: 20px;
    padding-right: 20px;
}
.plateformBoxFirst .in{padding: 0px 20px 50px 20px;}
}
@media (max-width:767px) {
  
    .Platform br{display: none;}
    .Data-Driven-section .number-box { margin: 0px auto 20px auto; display: flex; }
    .Data-Driven-section .grid-outer .MuiGrid-root {text-align: center;}
    .Data-Driven-section h2{text-align: center;}
    #plateformBox ul{display: block;}
    #plateformBox ul li{display: block; width:100%; padding-bottom:30px;}
    .plateformBoxFirst .in{padding: 0px 20px 30px 20px;}

}