.job-page .job-border{border-bottom: 1px solid #C0CCD2; margin: 20px 0px 60px 0px;}
.job-page .inner-div { max-width: 595px;}
.job-page .hs-form-.hs-form-1af92a5c-b450-483a-b450-f7edb713e64b_d1e37310-7907-4469-aa0e-4c0cbe968792.hs-form-b450-483a-b450-f7edb713e64b_d1e37310-7907-4469-aa0e-4c0cbe968792.hs-form {
    width: 100% !important;
}
.job-desciption ul{margin-left: 18px;}
.job-desciption ul li::marker {font-size: 18px;color:#2E5668;}
.job-page .Requirements{padding-top: 60px;}
.job-page .link{color:#45BEA0 !important; font-weight: 700; padding-top: 40px; text-decoration: underline;}
.job-page .Details ul{display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;}
    .dec ul{list-style: none; margin-left: 0px !important;}
    .job-page .Details{padding-top: 30px;}
    .job-page .Details ul li{width:42%;}
    .job-page .inner-list{list-style: disc !important; margin-left: 40px !important;}
@media (min-width: 768px) and (max-width: 1023px){

    .job-page .inner-div {
        max-width: 100%;
    }
    .job-page .inner-list{margin-left: 20px !important;}
    }
@media (min-width: 320px) and (max-width: 767px){
    .job-page .inner-list{margin-left: 20px !important;}
.job-page .inner-div {
    max-width: 100%;
}
.job-page .Details ul li {
    width: 100%;
}
}