

.banner{width: 100%; }
img{max-width: 100%;}
.inner-div{max-width: 530px;}
.vertical-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.svg-icon-color-blue{
    fill: #45BEA0;
    font-size: 35px ;
}
.company-values svg{
    width:40px;
    height:36px;
    fill:#45BEA0;
}
.company-values svg path{
    fill:#45BEA0;
}
.company-values .eye-icon{width: 16px;
    height: 17px;
    position: absolute;
    margin-left: -28px;
    margin-top: 7px;}
    .company-values .setting-icon{    width: 22px;
        background: #fff;
        border-radius: 50%;
        height: 22px;
        padding: 2px;
        position: absolute;
        margin-top: 20px;
        margin-left: -18px;}
        .color{background-color: #45BEA0; border-radius: 8px!important;}
       
        .slick-slider{max-width: 550px; }
        .slick-dots{bottom:-18px !important;}
        .slick-dots li button:before{font-size:12px !important ;}
        .slick-dots li button:before{color:#fff !important; opacity: 1 !important;}
        .slick-dots li.slick-active button:before{color: #45BEA0 !important;}
        .slick-dots li.slick-hover button:before{color: #45BEA0 !important;}
        .slick-slide img {margin: auto; }
        .home-dori-plateform-box{
          
                position: relative;
                left: -50px;
                background-color: #254553;border-radius: 8px !important; padding:20px; }
                .ComputerVision .inner-div {padding-right: 70px;}
        
/*third section*/
.MuiSvgIcon-root.svg-icon-color-blue {
    fill: #45BEA0;
    font-size: 35px ;
}
.grid-outer{
    justify-content:space-between;
}
.grid-outer .MuiGrid-root{
    max-width:358px;
    box-shadow: 0px 8px 16px #00000014;
    border: 1px solid #C0CCD2;
    border-radius: 4px;
    padding: 48px 30px;
}
.company-max-width{max-width: 530px; margin:auto;}
.company-values{ background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;

}
.company-values h4{min-height: 70px; background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;

}

/*fourth section cae study*/
.case-study-inner .box-inner .box{box-shadow: 0px 8px 16px #00000014; border-radius:8px; background-color: white;}
.case-study-inner .box-inner .box{ border-radius:8px; display:flex; }
.case-study-inner .box-inner .box1{width: 30%;}
.case-study-inner .box-inner .box2{width: 70%;}
.case-study-inner .box-inner .inner-text{padding:30px;}
.case-study-inner .box-inner img{border-radius: 8px 0px 0px 8px;}
.case-study-inner .box-inner ul {display: flex; list-style: none; padding-top:20px; flex-wrap: wrap;}
.case-study-inner .box-inner ul li{width:48%; list-style: none;}
.border{border-bottom:1px solid #C0CCD2; margin-bottom:20px; margin-top: 6px;}
.case-study-anchor{color:#1D343E;}
.case-study-anchor:hover{color:#45BEA0;}
/**trusted by section*/
.trusted-by-section .box-inner .box{box-shadow: 0px 8px 16px #00000014; border-radius:8px; background-color: white;}
.trusted-by-section .box-inner .box{ border-radius:8px;}

.trusted-by-section .box-inner .inner-text{padding:30px 14.6px; }
.trusted-by-section .box-inner img{border-radius: 8px 8px 0px 0px; }
.trusted-by-section .box-inner .trusted-box1{width: 100%;}
.trusted-by-section .box-inner .trusted-box2{width: 100%;}

/*contact us*/
.contact-us-section .contact-inner{background-color: lightgray;}
.contact-us-section .contact-inner{padding:60px 60px 60px 60px;}
.contact-us-section .form-col{width:100%; display: inline-flex; justify-content: space-between;}
.contact-us-section .form-col input:nth-child(1) {margin-right: 30px;}
.contact-us-section input{background: transparent !important;
    width: 100%;
    padding-bottom: 10px;
    background-color: transparent !important;
    border: none!important;
    border-bottom: 1px solid #FFFFFF !important;
    padding-left: 0px;
    outline: none!important;
    color: #fff;
    font-size: 14px;
    line-height: 21px;
    width: 100%;
    transition: all 0.01s ease-in-out;}
    .contact-us-section textarea {
        width: 100%;
    padding-bottom: 10px;
    background-color: transparent !important;
    border: none!important;
    border-bottom: 1px solid #FFFFFF !important;
    padding-left: 0px;
    outline: none!important;
    color: #fff;
    font-size: 14px;
    line-height: 21px;
    width: 100%;
    transition: all 0.01s ease-in-out;
    }
    .contact-inner{position: relative;}
    .form-control{margin-bottom: 30px;}
    .contact-inner input::placeholder {
        color: #FFFFFF;
      }
      .contact-inner textarea::placeholder {
        color: #FFFFFF;
      }

      .overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.7);
        transition: opacity 500ms;
        visibility: hidden;
        opacity: 0;
        z-index: 999;
      }
      .overlay:target {
        visibility: visible;
        opacity: 1;
        z-index: 9999999999;
      }
      
      .popup {
        width: 100%;
        position: fixed;
        top:0px;
        bottom: 0px;
        transition: all 5s ease-in-out;
       
      }
            .popup .close {
        position: absolute;
        top: 20px;
        right: 30px;
        font-size: 35px;
        transition: all 200ms;
        text-decoration: none;
        color: #fff;
      }
      .popup .close:hover {
        color: #45BEA0;
      }
      .mobile{display: none;}
      .desktop{display:block;}
     
@media (max-width:767px) {
    .slick-slide img { height: 100%;width: 100%;}
    .home-dori-plateform-box{left:0px;}
    /*.home-about{padding-left: 20px; padding-right: 20px;}*/
    .ComputerVision .inner-div{max-width:100%;padding-right: 0px;}
    .slick-slider{max-width: 324px;  padding: 0px 0px;}
    
    /*second section*/
    .extra br{display: none;}
    .mobile{display: block;}
    .desktop{display:none;}
    .inner-div {max-width: 100%; padding-bottom: 36px;}
    .company-values h4 {min-height: auto;}
    /*second section*/
    /*third section*/

    .trusted-by-section .box-inner .trusted-box1{width: 100%;}
.trusted-by-section .box-inner .trusted-box2{width: 100%;}
.trusted-by-section .box-inner img {
    border-radius: 8px 8px 0px 0px;
    width: 100%;
    height: auto;
}
.trusted-by-section .box-inner .box {
    max-height: 100% !important;
    display: block;
    
}
    .case-study-inner .box-inner .box {border-radius: 8px;display: block;  }
    .case-study-inner .box-inner .box1 {width: 100%;}
    .case-study-inner .box-inner .box2 {width: 100%;}
    .case-study-inner .box-inner img {border-radius: 8px 8px 0px 0px;}
    /*end third section*/
    .grid-outer .MuiGrid-root:nth-child(2){
        margin:20px 0px;
    }
    .grid-outer .MuiGrid-root {
        max-width:100%;
    }
    .case-study-inner .box-inner .inner-text{padding:20px;}
    .copyright {text-align: center !important;  padding-top: 10px;}
    .sub-footer {   text-align: center; }
    .contact-us-section .contact-inner {
        padding: 150px 20px;
    }
}
@media (min-width:600px) and (max-width:767px) {
    .slick-slider{max-width: 550px;  padding: 0px 0px;}
     }
@media (max-width:393px) {
    .slick-slider{max-width: 285px;  padding: 0px 0px;}
     }
     @media (max-width:360px) {
        .slick-slider{max-width: 270px;  padding: 0px 0px;}
         }
          
         @media (max-width:320px) {
            .slick-slider{max-width: 240px;  padding: 0px 0px;}
             }

@media (min-width:768px) and (max-width:1023px) {
    .home-dori-plateform-box{left:0px;}
    .home-about{padding-left: 20px; padding-right: 20px;}
    .ComputerVision .inner-div{max-width:100%;padding-right: 0px;}
    .slick-slider{max-width: 680px;  padding: 20px 20px;}
    .slick-slide img { height: 100%;width: 100%;}

    /*second section*/
    .inner-div {max-width: 100%; padding-bottom: 30px;}
    .case-study-inner .box-inner .box1 {
        width: 23%;
    }
    .extra br{display: none;}
    /*end second section*/
    .grid-outer .MuiGrid-root:nth-child(3){
        margin-top: 20px;
    }
    .contact-us-section .contact-inner {
        padding: 150px 60px 60px 60px;
    }
    .form-control {
        margin-bottom: 48px;
    }
   
}

@media (min-width:820px) and (max-width:1023px) {
    .slick-slider{max-width: 740px;  padding: 20px 20px;}
}