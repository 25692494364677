.postTitle{max-width: 800px;}
.blogauther{display: flex;}
.author-image-row {
   
    display: flex;
}
.author-detail {
    text-align: left;
    padding-left: 30px;
    display: flex;
    align-items: center;
}
.social-share {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.social-share span{padding-right: 15px;}
.custom-container2{max-width: 850px; margin: auto;}
.ad_box{}
.ad_box h2{color:#001E33; font-size: 30px; line-height: 37.5px; font-weight: 900; padding-bottom: 20px; padding-top: 72px;}
.ad_box img{padding-bottom: 30px;}
.ad_box p{font-size:20px;
line-height:32.3px;
letter-spacing:0px; color:#2E5668; }
.ad_box h4 {font-size:20px;
    line-height:32.3px;
    letter-spacing:0px; color:#2E5668; font-weight: 700; padding: 47px 0px 25px 0px; color:#001E33;}
    .ad_box ul{padding-left: 27px;}
    .ad_box li{font-size:20px;
        line-height:32.3px;
        letter-spacing:0px; color:#2E5668; }
        .ad_box li::marker{color:#45BEA0 !important;}
        @media (min-width: 768px) and (max-width: 1023px){

        
        }
    @media (min-width: 320px) and (max-width: 767px){

        .ad_box h2{ font-size:24px;
            line-height:30px;
            letter-spacing:0px;  padding-top: 60px;}
            .singlerecentpost {display: block;  width: 100%; padding-right: 30px; padding-bottom: 40px;}
            .singlerecentpost h2 {min-height: auto;padding-bottom: 10px; }
            .author-image-row {display: block;text-align: center;}
            .blogauther { display: block; }
            .author-detail {  text-align: center;display: block; }
            .social-share {width: 100%;display: block;text-align: center;margin-top: 30px;}
            .share-buttons{padding-top: 15px;}
        
    }