.relatedPosts .border-blog {
    border-bottom: 1px solid #C0CCD2 !important;
    margin-bottom: 20px ;
    margin-top: 20px ;
    }
    .singlerecentpost{display: inline-flex; flex-wrap: wrap; width:33%; padding-right: 30px; } 
    .relatedPosts .singlerecentpost:last-child{padding-right: 0px;}
    .singlerecentpost h2{min-height: 10vh;}
    .recentblog p{max-width: 700px; margin: auto;}
    .relatedPosts p{color:#BFBFBF;}
    @media (min-width: 320px) and (max-width: 767px){

        
            .singlerecentpost {display: block;  width: 100%; padding-right: 30px; padding-bottom: 40px;}
            .singlerecentpost h2 {min-height: auto;padding-bottom: 10px; }
            
        
    }